import React, { useEffect, useRef, useState } from 'react'
import ClientProvider from '../../Various/ClientProvider';
import StdOutLogElement from './StdOutLogElement'
import CompWrapper from '../CompWrapper'
import Button from "react-bootstrap/Button";

export default function StdOutLog({ stdOutLog }) {
    const wsProvider = React.useContext(ClientProvider);
    const [streaming, setStreaming] = useState(false);
    const messagesEndRef = useRef(null)

    const streamingOptions = {
        sessionTimeout: 180,
        LogGroupIdentifiers: JSON.parse(process.env.REACT_APP_CLOUDWATCH_LOG_GROUP_IDENTIFIER),
        filterPattern:""
    }
    /*
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    useEffect(() => {
        scrollToBottom()
      }, [wsProvider.stdOutLog]);
    */

    const listItems = stdOutLog.slice(0,200).map((element) =>
        <StdOutLogElement output={element["output"]} key={element["key"]} />
    );

    const toggleStreaming = () => {

        if (streaming) {
            setStreaming(!streaming);
            wsProvider.ws.waitForSocketAndSend({
                action: "stopStreamingCloudwatchLogs",

            }, (response) => {
                console.log(response)

                //  alert("stopBatchJobItem: " + filterFormData.batchJobId + "/" + item["path"])
            })
        }
        else {
            wsProvider.ws.waitForSocketAndSend({
                action: "startStreamingCloudwatchLogs",
                options: streamingOptions
            }, (response) => {
                console.log(response)
                setStreaming(!streaming);
                //  alert("stopBatchJobItem: " + filterFormData.batchJobId + "/" + item["path"])
            })
        }
    }

    return (
        <CompWrapper
            className="stdOutLog"
            title={"Log (" + listItems.length + ")"}
            buttonBar={(
                <>
                    {listItems.length > 0 &&
                        <button
                            className="btn btn-primary btn-sm"
                            type="button"
                            onClick={wsProvider.clearLog}
                        >X</button>
                    }
                </>
            )}
        >
            <Button variant="secondary" size="sm"  onClick={() => {
               toggleStreaming()
            }}>Stream CloudWatch Logs</Button>
            {listItems}
            <div ref={messagesEndRef} />
        </CompWrapper>
    );
} 